import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";

(async function () {
  Amplify.configure(awsconfig);

  let searchParams = new URLSearchParams(location.search);

  // user_id があったら保存
  if (searchParams.has("user_id")) {
    document.cookie = `user_id=${searchParams.get("user_id")}; Domain=${
      window.location.hostname
    }; Path=/; Max-Age=31536000;`;
  }

  let formValues = {
    family_name: "",
    given_name: "",
    phone_number_0: "",
    phone_number_1: "",
    phone_number_2: "",
    email: "",
    password: "",
    password_1: "",
    q: location.search,
  };

  let formErrors;

  const handleChange = (e, btn) => {
    const {
      target: { name, value },
    } = e;

    formValues = { ...formValues, [name]: value };

    btn.disabled =
      Object.keys(formValues).some(
        (k) => k != "q" && formValues[k].length == 0
      ) || formValues.password != formValues.password_1;
  };

  const handleSubmit = async (el) => {
    el.addEventListener("submit", async (e) => {
      e.preventDefault();

      try {
        searchParams.append("registration_type", "matomete.solar");
        searchParams.append(
          "registration_platform",
          navigator.userAgent.match(/iPhone|Android.+Mobile/) ? "sp" : "pc"
        );

        const userIdCookie = document.cookie
          .split("; ")
          .find((item) => item.indexOf("user_id") === 0);
        if (userIdCookie) {
          searchParams.append("user_id", userIdCookie.split("=")[1]);
        }

        const body = {
          ...formValues,
          phone_number: [
            formValues.phone_number_0,
            formValues.phone_number_1,
            formValues.phone_number_2,
          ].join(""),
          q: searchParams.toString(),
        };
        const r0 = await fetch(`${API_ENDPOINT_SOLSELL}v1/users/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });

        if (r0.status >= 400) {
          const { error } = await r0.json();
          renderFormErrors(error);
          return;
        }

        location.href = `${URL_MATOMETE_SOLAR}verify`;
        return;
      } catch (err) {
        renderFormErrors(err.toString());
      }
    });
  };

  const handleToggle = (toggler, form) => {
    toggler.click((e) => {
      form.slideToggle();
      toggler.toggleClass("open");
    });
  };

  const renderFormErrors = (err) => {
    const fes = document.querySelectorAll(".form-error");
    for (let i = 0; i < fes.length; i++) {
      fes[i].remove();
    }

    if (!err) {
      return;
    }

    const form = document.getElementById("registration-form");
    if (typeof err == "string") {
      const errorEl = document.createElement("div");
      errorEl.classList.add("form-error");
      errorEl.textContent = err;

      const firstRow = form.querySelector(".row:first-child");
      form.insertBefore(errorEl, firstRow);
    } else {
      Object.keys(err).map((k) => {
        let key = k;
        if (key == "phone_number") {
          key = "phone_number_0";
        }
        const input = form.querySelector(`input[name=${key}]`);
        if (input) {
          const errorEl = document.createElement("div");
          errorEl.classList.add("form-error");

          err[k].map((item) => {
            const errorItemEl = document.createElement("div");
            errorItemEl.textContent = item;
            errorEl.appendChild(errorItemEl);
          });

          input.parentNode.appendChild(errorEl);
        }
      });
    }
  };

  const btn = document.getElementById("btn-signup");
  const inputs = document.querySelectorAll(".input-item");
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener("input", (e) => {
      handleChange(e, btn);
    });
  }

  const signupGoogle = document.getElementById("signup-google");
  if (signupGoogle) {
    signupGoogle.addEventListener("click", (e) => {
      return Auth.federatedSignIn({ provider: "Google" });
    });
  }

  const signupApple = document.getElementById("signup-apple");
  if (signupApple) {
    signupApple.addEventListener("click", (e) => {
      return Auth.federatedSignIn({ provider: "SignInWithApple" });
    });
  }

  const toggler = $("#signup-email");
  const registrationForm = $("#registration-form");
  if (toggler && registrationForm) {
    handleToggle(toggler, registrationForm);
    handleSubmit(document.getElementById("registration-form"));
  }
})();
